import React from "react"
import ImageAgencia from "../inicio/images/image-agencia"
import ImageStaffing from "../inicio/images/image-staffing"
import ImageAcademia from "../inicio/images/image-academia"

import "../../scss/home-como-lo-hacemos.scss"

const ComoLoHacemos = () => (
<>
<div className="row bkg-gray">
  <div className="col txt-container">
    <h3 className="h3--black padding-container">Agencia de <br></br>software</h3>
    <p className="txt-description padding-container">Creamos software para atender los retos presentes y futuros de tu organización y del mercado.</p>
  </div>
  <div className="col image__responsive">
    <ImageAgencia />
  </div>
</div>
<div className="row bkg-gray row__inverse">
  <div className="col image__responsive">
    <ImageStaffing />
  </div>
  <div className="col txt-container">
    <h3 className="h3--black padding-container">Staffing TI</h3>
    <p className="txt-description padding-container">Proveemos a tu organización de un equipo de expertos para trabajar en tus proyectos de desarrollo de software.</p>
  </div>
</div> 
<div className="row bkg-gray">
  <div className="col txt-container">
    <h3 className="h3--black padding-container">Academia de<br></br>software</h3>
    <p className="txt-description padding-container">
      ¿Eres un desarrollador y te interesa construir software de clase mundial? 
      <br /><br />¡Únete al equipo!<br className="show-desktop" /><br className="show-desktop" /><br />
    </p>
  </div>
  <div className="col image__responsive">
    <ImageAcademia />
  </div>
</div>
</>
);

export default ComoLoHacemos