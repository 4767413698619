import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Link } from "gatsby"

import BackgroundImage from 'gatsby-background-image'

const BackgroundAgencia = () => (
  <StaticQuery query = { graphql `
      query {
        desktop: file(relativePath: { eq: "agencia.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    ` }
  render = {
    data => {
      const imageData = data.desktop.childImageSharp.fluid
      return ( <BackgroundImage Tag = "section"
            fluid = { imageData }
            className="banner-home agencia"
            backgroundColor = { `#FFF` }>
          <h3 className="text-white text-center">
          Agencia de <span className="bold"><br></br>software</span>
          </h3>
          <Link className="btn-default" to="/agencia-de-software">Descubrir</Link>
        </BackgroundImage>
      )
    }
  }
  />
)


export default BackgroundAgencia