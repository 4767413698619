import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Link } from "gatsby"

import BackgroundImage from 'gatsby-background-image'

const BackgroundAgencia = () => (
  <StaticQuery query = { graphql `
      query {
        desktop: file(relativePath: { eq: "staffing.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    ` }
  render = {
    data => {
      const imageData = data.desktop.childImageSharp.fluid
      return ( <BackgroundImage Tag = "section"
            fluid = { imageData }
            className="banner-home staffing"
            backgroundColor = { `#FFF` } >
            <h3 className="text-white text-center">
            <span className="bold">Staffing</span> TI
          </h3>
          <Link className="btn-default" to="/staffing-ti">Descubrir</Link>
        </BackgroundImage>
      )
    }
  }
  />
)


export default BackgroundAgencia