import React from "react"

import BackgroundAgencia from "./background-images/background-agencia"
import BackgroundStaffing from "./background-images/background-staffing"
import BackgroundAcademia from "./background-images/background-academia"

import "../../scss/home-que-hacemos.scss"

const QueHacemos = () => (
  <div className="container padding-0">
    <div className="row">
      <div className="col col-1">
        <BackgroundAgencia alt="Agencia y desarrollo de software" />
      </div>
      <div className="col col-2">
        <BackgroundStaffing />
        <BackgroundAcademia />
      </div>
    </div>  
  </div>
)

export default QueHacemos