import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import videomp4 from "../videos/video-background-home.mp4"
import poster from "../images/poster-home.jpg"
import TitleH2 from "../components/globals/title-h2"
import QueHacemos from "../components/inicio/que-hacemos"
import ComoLoHacemos from "../components/inicio/como-lo-hacemos"

const IndexPage = () => (
  <Layout footer={true}>
    <SEO title="Inicio" keywords={[`develop`, `high-flyers`, `sofware`]} />
    <div className="video-background-container">
      <h1 className="message bold">
        Desarrollamos software ágil y a la medida
      </h1>
      <video autoPlay loop muted poster={poster}>
        <source src={videomp4} type="video/mp4" />
      </video>
    </div>
    <TitleH2 titleName="¿Qué Hacemos?" align="center bkg-white" />
    <QueHacemos />
    <div className="container padding-0">
      <div className="container-warped">
        <TitleH2 titleName="¿Cómo lo hacemos?" align="left" />
        <h4 className="txt-description">Nuestro equipo cuenta con más de 20 años de experiencia trabajando para empresas y organizaciones como: bancos, aseguradoras, casas de bolsa, AFOREs, SOFOMes, farmacéuticas, e-commerce y gobierno.</h4>
      </div>
    </div>
    <ComoLoHacemos />
  </Layout>
)

export default IndexPage
