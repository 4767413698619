import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Link } from "gatsby"

import BackgroundImage from 'gatsby-background-image'

const BackgroundAcademia = () => (
  <StaticQuery query = { graphql `
      query {
        desktop: file(relativePath: { eq: "academia.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    ` }
  render = {
    data => {
      const imageData = data.desktop.childImageSharp.fluid
      return ( <BackgroundImage Tag = "section"
            fluid = { imageData }
            className="banner-home academia"
            backgroundColor = { `#FFF` } >
            <h3 className="text-white text-center">
            <span className="bold">Academia</span> de<br></br> software
          </h3>
          <Link className="btn-default" to="/academia-de-software">Descubrir</Link>
        </BackgroundImage>
      )
    }
  }
  />
)


export default BackgroundAcademia